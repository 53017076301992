@import "../../../../../node_modules/ngx-bootstrap/datepicker/bs-datepicker.scss";

/* Fix header text placement */
.bs-datepicker-head > bs-datepicker-navigation-view {
    display: flex;
    justify-content: space-around;
}

/* Custom Theme */
.theme-dark-blue {
    .bs-datepicker-container {
        padding: 0;
    }
    .bs-datepicker-head {
        background-color: transparent;
        border-radius: 0;
        
        button {
            color: inherit;
        }
    }
    .bs-datepicker-body {
        border: 0;
        border-radius: 0;

        table td {
            span.selected, &.selected span,
            span[class*="select-"]:after,
            &[class*="select-"] span:after {
                background-color: $primary;
            }
            &.week span {
                color: inherit;
            }
        }
    }
}