/* You can add global styles to this file, and also import other style files */

//== Vendor
@import '@fortawesome/fontawesome-free-webfonts/css/fa-brands.css';
@import '@fortawesome/fontawesome-free-webfonts/css/fa-regular.css';
@import '@fortawesome/fontawesome-free-webfonts/css/fa-solid.css';
@import '@fortawesome/fontawesome-free-webfonts/css/fontawesome.css';
@import 'simple-line-icons/css/simple-line-icons.css';

@import 'loaders.css/loaders.css';

@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

@import 'ika.jvectormap/jquery-jvectormap-1.2.2.css';

@import 'jqcloud2/dist/jqcloud.css';

@import 'summernote/dist/summernote.css';

@import "../node_modules/@swimlane/ngx-datatable/index.css";
@import "../node_modules/@swimlane/ngx-datatable/assets/icons.css";

@import "@ng-select/ng-select/themes/material.theme.css";

@import "float-label-css/dist/float-label.min.css";

//== Bootstrap
@import "./app/shared/styles/bootstrap.scss";
//== Application
@import "./app/shared/styles/app.scss";

body {
    padding-right: 0 !important;
}

@media only screen and (max-width: 600px) {
    .form {
       width:320px;
       margin: 0 auto;
       display:block

    }
}

.user-image{
    display: inline;
    width: 25px;
}

.user-image img{
    margin: 15px 0;
    width: 25px;
}
.rounded-img{
    border-radius: 50%;
    border: 1px solid #ffffff;
    background-color: #fff;
}

.user-info{
    display: inline;
    margin: 4px 10px;
    padding: 3px 10px;
    border-radius: 10px;
    font-size: 11px;
    font-weight: 700;
}

.light-bg{
    background: #e9e8ef;
    color: #46435d;
}

.dark-bg{
    color: #e9e8ef;
    background: #46435d;
}

.green-bg {
    background: #69e4a6;
    color: #46435d;
}

.green-bg:focus, .green-bg:hover:not([disabled]){
    background: #38c47e;
}

.blue-bg{
    background: #3b86ff;
    color: #e9e8ef;
}

.blue-bg:focus, .blue-bg:hover:not([disabled]){
    background: #1062e5
}

.p-0{
    padding: 0px !important;
}

.pr-10{
    padding-right: 10px;
}

// Bootstrap 5 modal management

.modal.fade.show {
    opacity: 1;
}
.modal.show .modal-dialog {
    transform: none;
}
.modal-backdrop.show {
    opacity: .5;
}

.close.pull-right > span {
    font-size: 1.6rem;
}

h4 {
    color: #000000DE !important;
}